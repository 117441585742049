import { useContext } from 'react';
import { graphql } from 'gatsby';
import { block } from 'bem-cn';
import loadable from '@loadable/component';

import Layout from 'layouts/';
import Heading from 'components/Heading';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import Container from 'components/Container';
import {
	faqItem,
	insideLink,
	faqBlock,
	renderAst,
} from 'components/markDownComponents';

import { DeviceContext } from 'layouts/context';

const renderComToolkit = {
	faq: faqBlock,
	'faq-item': faqItem,
	'inside-link': insideLink,
};

const faqRenderCom = renderAst(renderComToolkit);

import './style.scss';

const cn = block('main-page');

const GeneralBanner = loadable(() => import('components/_main-page/Banner'));
const MainTabs = loadable(() => import('components/Tabs'));
const MobileTabs = loadable(() => import('components/Tabs/_mobile'));
const FunctionsBoost = loadable(() =>
	import('components/_main-page/FunctionsBoost')
);
const VastArea = loadable(() => import('components/_main-page/VastArea'));
const GetStartedBlock = loadable(() => import('components/GetStarted'));
const Testimonials = loadable(() => import('components/Testimonials'));

const Home = ({ data, ...props }) => {
	const { isMobile, isTablet } = useContext(DeviceContext);

	const {
		seo,
		title,
		bannerDescription,
		features,
		functions,
		testimonial,
		vastApplications,
	} = data.markdownRemark.frontmatter;

	const { htmlAst } = data.markdownRemark;
	const mainBannerImage = isTablet
		? 'main-banner-tablet.png'
		: 'home page screenshot test.png';

	return (
		<Layout seo={seo}>
			<Container className={cn('container', { general: true })}>
				<Heading
					color='general'
					weight='bold'
					className={cn('heading')}
					align='center'
				>
					{title}
				</Heading>
				<div className={cn('description', { wrap: true })}>
					{bannerDescription.map((item) => (
						<Paragraph key={item} className={cn('description', { text: true })}>
							{item}
						</Paragraph>
					))}
				</div>
				<GeneralBanner className={cn('slider')} />
				<section
					className={cn('section', { features: true })}
					role='contentinfo'
					aria-label='Feature product'
				>
					<Heading
						level={2}
						weight='bold'
						className={cn('section', { heading: true })}
					>
						{features.title}
					</Heading>
					<Title className={cn('section', { subHeading: true })}>
						{features.subtitle}
					</Title>
					<div className={cn('tabs')}>
						{isMobile ? (
							<MobileTabs data={features.tabs} />
						) : (
							<MainTabs data={features.tabs} />
						)}
					</div>
				</section>
			</Container>
			<section role='contentinfo' aria-label='Over 340 functions presentation'>
				<Container
					className={cn('container', { 'functions-presentation': true })}
					withBackground
					backgroundContainerClassName={cn('container', {
						'functions-presentation': 'background',
					})}
				>
					<Heading
						level={2}
						weight='bold'
						align='center'
						className={cn('section', { heading: true })}
					>
						{functions.title}
					</Heading>
					<Title className={cn('section', { subHeading: true })}>
						{functions.subtitle}
					</Title>
					<div className={cn('functionsBoost')}>
						<FunctionsBoost data={functions.functionsList} />
					</div>
					<Title level={9}>{functions.remark}</Title>
				</Container>
			</section>
			<Container className={cn('container', { 'vast-application': true })}>
				<section role='contentinfo' aria-label='Vast application'>
					<Heading
						level={2}
						weight='bold'
						className={cn('section', { heading: true })}
					>
						{vastApplications.title}
					</Heading>
					<Title className={cn('section', { subHeading: true })}>
						{vastApplications.subtitle}
					</Title>
					<div className={cn('functionsBoost')}>
						<VastArea data={vastApplications.vastList} />
					</div>
				</section>
			</Container>
			<section
				className={cn('section', { 'get-started': true })}
				role='contentinfo'
				aria-label='Get started'
			>
				<GetStartedBlock />
			</section>
			<Container className={cn('container', { last: true })}>
				{/* <section
					className={cn('section', { testimonials: true })}
					role='contentinfo'
					aria-label='Testimonials'
				>
					<Heading
						level={2}
						weight='bold'
						className={cn('testimonials', { heading: true })}
					>
						{testimonial.title}
					</Heading>
					<Testimonials
						className={cn('testimonials')}
						data={testimonial.testimonialsList}
					/>
				</section> */}
				{faqRenderCom(htmlAst)}
			</Container>
		</Layout>
	);
};

export default Home;

export const query = graphql`
	query getMainPageData {
		markdownRemark(frontmatter: { slug: { eq: "/" } }) {
			htmlAst
			frontmatter {
				bannerDescription
				title
				seo {
					description
					image
					link
					title
				}
				features {
					subtitle
					tabs {
						content {
							description
							list
						}
						image
						alt
						title
						link {
							title
							to
						}
					}
					title
				}
				functions {
					functionsList {
						title
						functions {
							icon
							link
							name
							alt
						}
					}
					remark
					subtitle
					title
				}
				vastApplications {
					subtitle
					title
					vastList {
						title
						description
					}
				}
				testimonial {
					testimonialsList {
						author
						position
						testimonial
					}
					title
				}
			}
		}
	}
`;
